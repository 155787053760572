<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《纠纷处理方式》</h3>
        <p>1、武汉小咪网络科技有限公司，在此特别提醒用户在注册、登陆前请务必仔细阅读本《UPUPOO用户协议》及《UPUPOO激活码私用协议》（未成年人应当在其法定监护人陪同下阅读）中的各个条款，以确保您在享受壁纸服务的同时维护自身合法权益。</p>
        <p class="protocal-title-bold">2、【用户的违约及处理】</p>
        <p> 2.1 违约认定</p>
        <p>&nbsp;发生如下情形之一的，视为您违约：</p>
        <p>（一）使用武汉小咪网络科技有限公司产品时违反有关法律法规规定的；</p>
        <p>（二）补充协议：由于互联网高速发展，您与武汉小咪网络科技签署的本协议列明的条款并不能完整罗列并覆盖您与武汉小咪网络科技所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。</p>
        <p>为适应互联网行业发展和满足海量用户对高效优质服务的需求，您理解并同意，武汉小咪网络科技可在武汉小咪网络科技平台规则中约定违约认定的程序和标准。如：武汉小咪网络科技可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。</p>
        <p>2.2 违约处理措施</p>
        <p>您在武汉小咪网络科技平台上发布的内容和信息构成违约的，武汉小咪网络科技可根据相应规则立即对相应内容和信息进行删除、屏蔽等处理或对您的账户进行暂停使用、查封、冻结、注销等处理。 您在武汉小咪网络科技平台上实施的行为，或虽未在武汉小咪网络科技平台上实施但对武汉小咪网络科技平台及其用户产生影响的行为构成违约的，武汉小咪网络科技可依据相应规则对您的账户执行限制参加活动、中止向您提供部分或全部服务（如查封、冻结虚拟产品或清空账户等）处理措施。如您的行为构成根本违约的，武汉小咪网络科技可查封您的账户，终止向您提供服务。如您的账户被查封，您账户内的虚拟产品将被清空，相关虚拟产品服务将被终止提供。</p>
        <p>如果您在武汉小咪网络科技平台上的行为违反相关的法律法规，武汉小咪网络科技可依法向相关主管机关报告并提交您的使用记录和其他信息。同时，武汉小咪网络科技可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在武汉小咪网络科技平台上予以公示。除此之外，武汉小咪网络科技可依据国家相关法律法规规定，对您进行黑名单管理和信用管理，提供与信用等级挂钩的管理和服务，若您被纳入黑名单，有权对纳入黑名单的您采取禁止重新注册帐号的措施，并及时向相关部门报告。</p>
        <p>2.3赔偿责任</p>
        <p>如您的行为使武汉小咪网络科技遭受损失（包括但不限于自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿武汉小咪网络科技公司的上述全部损失。 如您的行为使武汉小咪网络科技公司遭受第三人主张权利，武汉小咪网络科技公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</p>
        <p>2.4特别约定</p>
        <p>武汉小咪网络科技负责"按现状"和"可得到"的状态向您提供武汉小咪网络科技平台服务。武汉小咪网络科技依法律规定承担相应义务，但无法对由于信息网络设备维护、连接故障，电脑、通讯或其他系统的故障，黑客活动、计算机病毒、电力故障，罢工，暴乱，火灾，洪水，风暴，爆炸，战争，政府行为，司法行政机关的命令或因第三方原因而给您造成的损害结果承担责任。</p>
        <p>武汉小咪网络科技通过中华人民共和国境内的设施控制和提供武汉小咪网络科技平台服务，武汉小咪网络科技不担保控制或提供的服务在其他国家或地区是适当的、可行的，任何在其他司法辖区使用武汉小咪网络科技平台服务的用户应自行确保其遵守当地的法律法规，武汉小咪网络科技对此不承担任何责任。</p>
        <p>2.5武汉小咪网络科技平台用户之间发生纠纷且无法协商一致的，可向国家相关行政机关或司法机关寻求解决包括但不限于向公安机关报案、申请仲裁或向人民法院提起诉讼等。武汉小咪网络科技有限公司将会为用户提供必要的协助和支持，并根据有关行政机关和司法机关的要求承担一定的举证责任或采取必要措施。</p>
        <p>
            <img src="../../assets/img/default/dispute_resolution.png">
        </p>
        <p class="protocal-title-bold">版权声明：</p>
        <p>免责声明</p>
        <p>1. 除武汉小咪网络科技注明的服务条款外，其他因使用武汉小咪网络科技而导致任何意外、疏忽、合约毁坏、诽谤、版权或知识产权侵犯及其所造成的各种 损失（包括因下载而感染电脑病毒），武汉小咪网络科技概不负责，亦不承担任何法律责任。</p>
        <p>2. 任何透过武汉小咪网络科技网页而链接得到之资讯、产品及服务，武汉小咪网络科技概不负责，亦不负任何法律责任。</p>
        <p>3. 武汉小咪网络科技产品内所有内容并不反映任何武汉小咪网络科技之意见及观点。</p>
        <p>4.武汉小咪网络科技不做任何与武汉小咪网络科技服务、产品的安全性、可靠性、及时性和性能有关的担保；且不保证其提供的任何产品、服务或其他材料符合用户的期望。</p>
        <p>5.用户应妥善保管自己的账号和密码，加强密码安全性，谨防账号泄露或被盗。因用户账号被泄露或被盗而造成的任何损失，武汉小咪网络科技不承担补偿责任。用户因电信和网通部门的通讯线路，网络或电脑故障、系统不稳定、不可抗力等非武汉小咪网络科技原因造成账号、账号内财产等丢失、减少的，武汉小咪网络科技不承担补偿等责任。</p>
        <p>6.用户因信息不真实导致账号、账号内财产等丢失、减少而无法找回的，武汉小咪网络科技不承担任何法律责任。</p>
        <p>7.武汉小咪网络科技认为，一切网民在使用武汉小咪网络科技服务时已经仔细看过本条款并完全同意。敬请谅解。</p>
        <p>客服邮箱：upupoo@upupoo.com</p>

    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;
  .h-bga {
    background: none;
    overflow-x: hidden;
  }
  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }
  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .protocol-head-container a {
    display: flex;
    align-items: center;
  }
  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }
  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }
  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }
  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }
  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }
  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
